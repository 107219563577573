<template>
  <v-layout>
    <vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal-container">
      <div v-if="isAlert" class="pop-alert v-card">
        <div class="v-card-actions">
          <div v-if="isIcon" class="icon" :class="iconClassName">
            <img v-if="getIconValue() != ''" :src="getIconValue(iconName)" alt="alert icon" />
          </div>
          <!-- 20240906 title 제거 : 문구로 대체
            <slot name="title"></slot>
          -->
          <slot :params="params"></slot>

          <!-- 20240906 변경 -->
          <v-spacer></v-spacer>
          <v-row class="btn-floor">
            <v-col class="px-0 py-0">
              <v-btn class="save w-100 m-0" :class="btnClassName" @click="$emit('confirm', close, 'confirm')">{{ confirmButtonName }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else-if="isConfirm" class="pop-alert-close v-card" :class="className">
        <!-- 20240906 추가 -->
        <div class="v-card-actions">
          <div v-if="isIcon" class="icon" :class="iconClassName">
            <img v-if="getIconValue(iconName) != ''" :src="getIconValue(iconName)" alt="confirm icon" />
          </div>
          <!-- 20240906 title 제거 : 문구로 대체
            <slot name="title"></slot>
          -->
          <slot :params="params"></slot>

          <!-- 20240906 변경 -->
          <v-spacer></v-spacer>
          <v-row v-if="isEmpty(cancelButtonName)" class="btn-floor full">
            <v-col class="px-0 py-0">
              <v-btn class="save w-100 m-0" :class="btnClassName" @click="$emit('confirm', close, 'secondResult')">{{ confirmButtonName }}</v-btn>
            </v-col>
          </v-row>
          <v-row v-else class="btn-floor half">
            <v-col cols="4" class="px-0 py-0">
              <v-btn class="cancel w-100" @click="$emit('cancel', close, 'firstResult')">{{ cancelButtonName }}</v-btn>
            </v-col>
            <v-col class="px-0 py-0">
              <v-btn class="save w-100 m-0" :class="btnClassName" @click="$emit('confirm', close, 'secondResult')">{{ confirmButtonName }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- 팝업 - 업로드 이미지 slider -->
      <!-- <div v-else class="pop-area" :class="modalName" :style="'max-height:' +  maxHeight + 'px; width: ' + width + 'px'"> -->
      <!-- :style="'max-height:' + maxHeight + 'px;'" -->
      <div v-else class="pop-area" :class="[modalName, className]">
        <div v-if="title && isTitle" class="pop-header" :class="headerClass">
          <div v-if="activeBtnBack" class="btn-back">
            <v-btn icon @click="clickBackBtn('cancel', close, 'back')">
              뒤로가기
              <v-icon>mdi-back</v-icon>
            </v-btn>
          </div>

          <!-- 20220711 이상훈 : h1 에서 h2로 교체 -->
          <h2 v-dompurify-html="title" class="tit"></h2>
          <div v-if="activeBtnClose" class="btn-del">
            <v-btn icon @click="$emit('cancel', close, 'close')">
              팝업닫기
              <v-icon>mdi-back</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="activeBtnClose && !isTitle" class="pop-header" :class="headerClass">
          <div class="btn-del">
            <v-btn icon @click="$emit('cancel', close, 'close')">
              팝업닫기
              <v-icon>mdi-back</v-icon>
            </v-btn>
          </div>
        </div>
        <component :is="slotContent" v-if="activeBtnBack" ref="slotContent" :params="params"></component>
        <slot v-else :params="params"></slot>
      </div>
    </vue-final-modal>
  </v-layout>
</template>
<script>
import { find, isEmpty } from 'lodash'
export default {
  name: 'UiModal',
  components: {},
  inheritAttrs: false,
  emits: ['confirm', 'cancel'],
  data() {
    return {
      activeBtnClose: true,
      activeBtnBack: false,
      isTitle: false,
      isAlert: false,
      isConfirm: false,
      cancelButtonName: '취소',
      confirmButtonName: '확인',
      modalName: '',
      className: '',
      title: '',
      isIcon: false,
      iconName: '',
      btnClassName: '',
      iconClassName: '',
      headerClass: ''
    }
  },
  computed: {
    maxHeight() {
      const _th = (this.$novaCom.windowHeight() - 100) % 2
      if (_th) return this.$novaCom.windowHeight() - 101
      return this.$novaCom.windowHeight() - 100
    },
    slotTitle() {
      return this.$pinia.popup.getTitle(this.$attrs.name)
    },
    slotContent() {
      const slot = this.$slots.default()
      if (import.meta.env.VUE_APP_BUILD_MODE === 'PRODUCTION') {
        return slot[0]
      } else {
        return slot[1]
      }
    }
  },
  watch: {
    slotTitle: {
      handler: function (val) {
        if (val) {
          this.title = val
        }
      },
      deep: true
    }
  },
  mounted() {
    const _self = this
    const modalParams = find(this.$modal.dynamicModals, function (item) {
      return item.bind.name === _self.$attrs.name
    })
    this.activeBtnClose = modalParams.params.activeBtnClose
    this.activeBtnBack = modalParams.params.activeBtnBack
    this.isTitle = modalParams.params.isTitle
    this.isAlert = modalParams.params.isAlert
    this.isConfirm = modalParams.params.isConfirm
    this.cancelButtonName = modalParams.params.cancelButtonName
    this.confirmButtonName = modalParams.params.confirmButtonName
    this.modalName = modalParams.params.modalName !== undefined ? modalParams.params.modalName : ''
    this.className = modalParams.params.className !== undefined ? modalParams.params.className : ''
    this.btnClassName = modalParams.params.btnClassName !== undefined ? modalParams.params.btnClassName : ''
    this.iconClassName = modalParams.params.iconClassName !== undefined ? modalParams.params.iconClassName : ''
    this.title = modalParams.params.title !== undefined ? modalParams.params.title : ''
    // 20240906 icon 내용 추가
    this.isIcon = modalParams.params.isIcon
    this.iconName = modalParams.params.iconName
    // this.width = modalParams.params.width !== undefined ? modalParams.params.width : '1300'
    // 20240911 팝업 헤더 클래스 추가
    this.headerClass = modalParams.params.headerClass
    this.$pinia.popup.changeTitle({ modalName: this.$attrs.modalName, title: this.title })
    console.log('[modal] modalParams.params', modalParams.params)
  },
  methods: {
    clickBackBtn(eventName, modalFn, clickBtnName) {
      console.log(eventName, modalFn, clickBtnName)
      console.log('slotContent', this.$refs.slotContent, typeof this.$refs.slotContent.backBtnClick)
      if (typeof this.$refs.slotContent.backBtnClick === 'function') {
        this.$refs.slotContent.backBtnClick()
      } else {
        // no action
      }
    },
    getIconValue(iconName) {
      const iconCode = this.$pinia.auth.getCommonCode('ST0028')
      const iconObj = find(iconCode, { value: iconName })
      if (isEmpty(iconObj)) {
        return '' // '/src/assets/main/popup01.png'
      } else {
        return '/assets/' + iconObj.note + iconObj.text
      }
    },
    isEmpty(val) {
      return isEmpty(val)
    }
  }
}
</script>
<style lang="scss"></style>
