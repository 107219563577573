// import axios from 'axios'
// import { isEmpty, filter, find } from 'lodash'
// import { event } from 'vue-gtag'

export default {
  state: () => ({
    siteTypeList: [],
    followNickNm: '', // 활동로그 팔로워/팔로잉 닉네임
    // 핀플채널, 오픈톡 헤더의 목록부
    siteType: '',
    // 검색 헤더 사용
    searchTypeList: [],
    searchTxt: '',
    searchType: '',
    // 의견남기기, 수정하기 헤더부
    opinionTitleList: [],
    opinionType: '',
    opinionModi: false, // 남기기,수정하기 버튼 활성화 여부
    opinionAction: false, // 남기기,수정하기 버튼 클릭 여부
    // 목록 이동 처리 부
    finfleUser: '',
    finfleSrhType: '',
    isFinfleDetailYn: '',
    openTalkType: '',
    openSrhType: '',
    isOpenDetailYn: ''
  }),
  getters: {
    /*
    getOpinionTitle: state => () => {
      state.opinionType
    }
    */
  },
  actions: {
    FOLLOW_NICK_NM_UPDATE(followNickNm) {
      // this.followNickNm.push(followNickNm)
      this.followNickNm = followNickNm
    },
    SITE_TYPE_LIST_UPDATE(siteTypeList) {
      this.siteTypeList = siteTypeList
    },
    SITE_TYPE_UPDATE(siteType) {
      this.siteType = siteType
    },
    SEARCH_TYPE_LIST_UPDATE(searchTypeList) {
      this.searchTypeList = searchTypeList
    },
    SEARCH_TEXT_UPDATE(searchTxt) {
      this.searchTxt = searchTxt
    },
    SEARCH_TYPE_UPDATE(searchType) {
      this.searchType = searchType
    },
    OPINION_ACTION_UPDATE(opinionAction) {
      this.opinionAction = opinionAction
    },
    OPINION_MODI_UPDATE(opinionModi) {
      this.opinionModi = opinionModi
    },
    OPINION_TYPE_UPDATE(opinionType) {
      this.opinionType = opinionType
    },
    OPINION_TITLE_UPDATE(opinionTitleList) {
      this.opinionTitleList = opinionTitleList
    }
  },
  persist: {
    paths: ['siteType', 'opinionTitleList', 'searchTypeList', 'followNickNm'],
    storage: sessionStorage,
    beforeRestore: ctx => {
      console.log(`store start '${ctx.store.$id}'`)
    },
    afterRestore: ctx => {
      console.log(`store complete '${ctx.store.$id}'`)
    }
  }
}
