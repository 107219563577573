import date from '$$utils/date'
import decimal from '$$utils/decimal'
import { find, isEmpty, forEach } from 'lodash'
import { useAuthStore } from '@/store'
const commonUtil = {
  randomValue: function (modVar) {
    const modBase = modVar || 100
    const array0 = new Uint32Array(1)
    window.crypto.getRandomValues(array0)
    const secureNumber0 = (array0[0] % modBase) + 1
    return secureNumber0
  },
  /**
   * 통화 콤마 표기
   * @param num {String, Number}
   * @param dotLength { Number}
   */
  ccyFormat: function (num, dotLength) {
    const numArray = String(num).split('.')
    const num1 = numArray[0]
    if (numArray[1] === undefined) numArray[1] = ''
    if (numArray[1].length < dotLength) {
      const zeroString = new Array(dotLength - numArray[1].length + 1).join('0')
      numArray[1] = numArray[1] + zeroString
    }
    const num2 = dotLength === 0 ? '' : '.' + numArray[1].substr(0, dotLength)
    const rtnNum = num1.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + num2
    return rtnNum
  },
  /**
   * 숫자 콤마 표기
   * @param num {String, Number}
   * @param dotLength { Number}
   */
  numberFormat: function (num, dotLength) {
    const numArray = String(num).split('.')
    const num1 = numArray[0]
    if (numArray[1] === undefined) numArray[1] = ''
    if (numArray[1].length < dotLength) {
      const zeroString = new Array(dotLength - numArray[1].length + 1).join('0')
      numArray[1] = numArray[1] + zeroString
    }
    const num2 = dotLength === 0 ? '' : '.' + numArray[1].substr(0, dotLength)
    const rtnNum = num1.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + num2
    return rtnNum
  },
  /**
   * 숫자 콤마 class 적용 표기
   * @param num {String, Number}
   */
  spanNumberFormat: function (num) {
    return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, '<span class="comma">,</span>')
  },
  /**
   * 숫자 콤마 제거
   * @param num {String, Number}
   */
  unNumberFormat: function (num) {
    return String(num).replace(/[^\d]+/g, '')
  },
  /**
   * 마스킹된 *를 &bull; 로 변경 콤마 제거하고 &bull 영역을 span으로 감싸 준다
   * @param v {String}
   */
  maskFormat: function (v) {
    let maskValue = ''
    for (let pos = 0; pos < v.length; pos++) {
      if (v[pos] === '*' && pos === 0) {
        maskValue = maskValue + '<span class="masking">*'
      } else if (v[pos] === '*' && v[pos - 1] !== '*') {
        maskValue = maskValue + '<span class="masking">*'
      } else if (v[pos] === '*' && v.length - 1 === pos) {
        maskValue = maskValue + '*</span>'
      } else if (v[pos] !== '*' && v[pos - 1] === '*') {
        maskValue = maskValue + '</span>' + v[pos]
      } else {
        maskValue = maskValue + v[pos]
      }
    }
    return String(maskValue.replace(/\*/g, '&bull;'))
  },
  /**
   * 날짜입력 값과 포멧을 받아 OUTPUT 포멧으로 리턴한다
   * @param v {String}
   * @param o {Object} inputFormat, outputFormat
   */
  dateFormat: function (v, o) {
    return date.format(v, o)
  },
  /**
   * Float 값을 받아 자리수 받큼 변환하여 리턴한다
   * @param v {String}
   * @param l {NUMBER}
   */
  decimalFormat: function (v, l) {
    return decimal.format(v, l)
  },
  /**
   * 현재 날짜
   */
  getDate: function () {
    const date = new Date()
    const year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    if (String(month).length === 1) {
      month = '0' + month
    }

    if (String(day).length === 1) {
      day = '0' + day
    }

    return { year, month, day }
  },
  /**
   * 금액 한글 표기
   * @param num {String, Number}
   */
  writeInKorean(num) {
    num = num.replace(/,/gi, '')
    const hanA = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십']
    const danA = ['', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천']
    let result = ''

    let i = 0

    const length = num.length

    for (i; i < num.length; i++) {
      let str = ''
      const han = hanA[num.charAt(num.length - (i + 1))]

      if (han !== '') str += han + danA[i]

      if (i === 4) {
        // 억단위 예외처리
        if (length >= 9) {
          if (parseInt(num.substr(1, length - 1)) > 10000) {
            str += '만'
          }
        } else {
          str += '만'
        }
      }
      if (i === 8) {
        str += '억'
      }
      if (i === 12) {
        str += '조'
      }

      result = str + result
    }
    if (num !== 0) {
      result = result + '원'
    }

    if (!result) {
      result = '0원'
    }
    return result
  },
  /**
   * 을 or 를 추가
   * @param label {String}
   */
  postPositionReturner(label) {
    const strGA = 44032 // 가
    const strHI = 55203 // 힣

    const lastStrCode = label.charCodeAt(label.length - 1)
    let prop = true
    let msg

    if (lastStrCode < strGA || lastStrCode > strHI) {
      return false // 한글이 아님
    }

    if ((lastStrCode - strGA) % 28 === 0) {
      prop = false
    }

    if (prop) {
      msg = label + '을'
    } else {
      msg = label + '를'
    }

    return msg
  },
  fileSize: function (size) {
    if (size > 1024 * 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
    } else if (size > 1024 * 1024 * 1024) {
      return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
    } else if (size > 1024 * 1024) {
      return (size / 1024 / 1024).toFixed(2) + ' MB'
    } else if (size > 1024) {
      return (size / 1024).toFixed(2) + ' KB'
    }
    return size.toString() + ' B'
  },
  /**
   * 버튼 권한 존재여부 체크
   * @param objAuthList [{object}] - [{"path": "/admin/code/90000", "url": "/ADMIN/C0/C0000M0/list", "roleType": "01"}]
   * @param currPath String - /admin/code/90000
   * @param arrAuthCode [String] - ['01', '05']
   * @return boolean true:권한 O, false: 권한 X
   */
  isBtnAuth: function (objAuthList, currPath, arrAuthCode) {
    let eventBreakInfo = null
    forEach(arrAuthCode, function (value) {
      const info = find(objAuthList, { path: currPath, roleType: value })
      if (!isEmpty(info)) {
        eventBreakInfo = info
        return true
      }
    })
    return !isEmpty(eventBreakInfo)
  },
  /**
   * 전화번호, 휴대폰번호 길이수에 따라 포맷후 리턴
   * @param phoneNumber {String}
   */
  formatPhoneNumber: function (phoneNumber) {
    let resultFormat = ''
    if (!isEmpty(phoneNumber)) {
      const replaceNum = phoneNumber.replaceAll(/[^0-9]/g, '')
      const numLength = replaceNum.length
      if (numLength === 8) {
        resultFormat = replaceNum.replace(/(\d{4})(\d{4})/, '$1-$2')
      } else if (replaceNum.startsWith('02') && (numLength === 9 || numLength === 10)) {
        resultFormat = replaceNum.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3')
      } else if (!replaceNum.startsWith('02') && (numLength === 10 || numLength === 11)) {
        resultFormat = replaceNum.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
      } else {
        resultFormat = ''
      }
    }
    return resultFormat
  },
  /**
   * 전화번호, 휴대폰번호 배열값으로 리턴
   * @param num {String}
   */
  getPhoneNumberArrList: function (val) {
    const replaceNum = val.replaceAll(/[^0-9]/g, '')
    const numLength = replaceNum.length
    let formatPhoneNumber = ''
    let returnArrayList = []
    if (replaceNum.startsWith('02') && (numLength === 9 || numLength === 10)) {
      // 지역번호가 서울일 경우
      formatPhoneNumber = replaceNum.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3')
      returnArrayList = formatPhoneNumber.split('-')
    } else if (!replaceNum.startsWith('02') && (numLength === 10 || numLength === 11)) {
      // 지역번호가 서울 외 타지역번호, 휴대폰번호 일 경우
      formatPhoneNumber = replaceNum.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
      returnArrayList = formatPhoneNumber.split('-')
    } else {
      // 조건식에 모두 해당하지 않을때 받은값 그대로 리턴
      formatPhoneNumber = val
    }
    return returnArrayList
  },
  $message: function (code, ...arg) {
    const authStore = useAuthStore()
    const message = authStore.getMessage(code, arg)
    return message
  },
  /**
   * () <> 네가지 문자열 replace
   * @param {String} val
   * @returns String
   */
  replaceSymbols: function (val) {
    if (isEmpty(val)) return ''
    else return val.replaceAll('&#60;', '<').replaceAll('&#62;', '>').replaceAll('&#40;', '(').replaceAll('&#41;', ')')
  }
}
export default commonUtil
